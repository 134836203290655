<template>
  <v-dialog v-model="showDialog" @click:outside="closeDialog">
    <v-card class="pa-2" outlined tile v-if="$store.state.user">
      <v-toolbar flat dense>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">Delete User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-toolbar-title></v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-container>
        <v-row dense>
          <v-col>
            Are you sure you want to delete the user account for
            <b>{{ $store.state.user.email }}</b
            >?<br />
            All related data will also be deleted, and you will be logged
            out.</v-col
          >
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn text color="error" @click="deleteUser">Delete</v-btn>
        <v-btn text @click="closeDialog()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  props: {},
  data() {
    return {
      showDialog: true
    }
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    async deleteUser() {
      this.$store.dispatch('pushLoading', 'deleteUser')
      try {
        const response = (
          await AuthenticationService.deleteUser(this.$store.state.user)
        ).data
        this.show = false
        this.$store.dispatch('setToken', null)
        this.$store.dispatch('setUser', null)
        this.$router.push({ name: 'about' })
        this.$store.dispatch('popLoading', 'deleteUser')

        this.$store.dispatch('setSuccess', response.message)
      } catch (error) {
        this.$store.dispatch('popLoading', 'deleteUser')

        this.$store.dispatch('setError', error.response.data.message)
      }
    }
  }
}
</script>
<style scoped></style>
