<template>
  <v-app id="inspire">
    <v-overlay v-if="$store.state.loadingArray.length > 0" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <page-header></page-header>
    <v-main>
      <v-card elevation="0" class="mx-2" min-height="600px" max-height="600px">
        <router-view />
      </v-card>
    </v-main>
    <v-snackbar
      v-if="$store.state.error"
      color="error"
      v-model="$store.state.error"
    >
      {{ $store.state.error }}

      <v-btn text @click="$store.state.error = false"> Close </v-btn>
    </v-snackbar>

    <v-snackbar
      v-if="$store.state.success"
      color="success"
      v-model="$store.state.success"
    >
      {{ $store.state.success }}

      <v-btn text @click="$store.state.success = false"> Close </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import PageHeader from '@/components/globals/PageHeader.vue'

export default {
  props: {
    source: String
  },
  data: () => ({}),

  created() {},
  components: {
    PageHeader
  }
}
</script>
<style scoped>
.scroll {
  height: '500px';
}
</style>
