import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AuthenticationService from '@/services/AuthenticationService'

Vue.use(Vuex)

// initial state
const state = () => ({
  token: null,
  user: null,
  query: null,
  success: null,
  error: null,
  loadingArray: []
})

const store = new Vuex.Store({
  string: true,
  //plugins: [createPersistedState()],
  state: state,
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    setError(state, error) {
      state.error = error
    },
    setSuccess(state, success) {
      state.success = success
    },
    pushLoading(state, process) {
      state.loadingArray.push(process)
    },
    popLoading(state, process) {
      state.loadingArray.pop(process)
    },

    refreshUser(state, user) {
      state.user = user
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setError({ commit }, error) {
      commit('setError', error)
    },
    setSuccess({ commit }, success) {
      commit('setSuccess', success)
    },
    pushLoading({ commit }, process) {
      commit('pushLoading', process)
    },
    popLoading({ commit }, process) {
      commit('popLoading', process)
    },

    async loadUser({ commit, state }) {
      var query = { userId: state.user.id }
      commit('refreshUser', (await AuthenticationService.getUser(query)).data)
    }
  }
})

export default store
