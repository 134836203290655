<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <v-form ref="messageForm" v-model="valid" lazy-validation>
          <v-text-field
            outlined
            label="Email"
            id="email"
            name="email"
            v-model="email"
            autocomplete="email"
            placeholder=" "
            persistent-placeholder
            :rules="emailRules"
            required
          ></v-text-field>
          <br />
          <v-textarea
            name="input-7-1"
            filled
            label="Message"
            auto-grow
            v-model="message"
            :rules="messageRules"
            required
          ></v-textarea>
          <br />

          <v-btn depressed color="primary" @click="sendMessage"
            >Send Message</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CoreService from '@/services/CoreService'

export default {
  data() {
    return {
      valid: true,

      email: '',
      message: '',
      messageRules: [(v) => !!v || 'Message is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  methods: {
    async sendMessage() {
      if (!this.$refs.messageForm.validate()) return false

      this.$store.dispatch('pushLoading', 'sendMessage')
      try {
        const response = (
          await CoreService.sendMessage({
            email: this.email,
            message: this.message
          })
        ).data
        console.log(response)
        this.$store.dispatch('setSuccess', response.message)
        this.$store.dispatch('popLoading', 'sendMessage')
      } catch (error) {
        this.$store.dispatch('popLoading', 'sendMessage')

        this.$store.dispatch('setError', error.response.data.message)
      }
    }
  },
  components: {}
}
</script>

<style scoped></style>
