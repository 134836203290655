import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import store from '@/store/store'
import VCurrencyField from 'v-currency-field'

Vue.config.productionTip = false

Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

sync(store, router)

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
