<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <form ref="form" name="loginForm" id="loginForm">
          <v-text-field
            outlined
            label="Email"
            id="email"
            name="email"
            v-model="email"
            autocomplete="email"
            placeholder=" "
            persistent-placeholder
          ></v-text-field>
          <br />
          <v-text-field
            outlined
            label="Password"
            type="password"
            name="password"
            v-model="password"
            autocomplete="current-password"
            placeholder=" "
            persistent-placeholder
          ></v-text-field>
          <br />

          <v-btn depressed color="primary" @click="login">Login</v-btn>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null,
      success: null
    }
  },
  methods: {
    async login() {
      this.$store.dispatch('pushLoading', 'login')
      try {
        const response = (
          await AuthenticationService.login({
            email: this.email,
            password: this.password
          })
        ).data
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)

        this.$store.dispatch('setSuccess', response.message)
        this.$store.dispatch('popLoading', 'login')
        this.$router.push({ name: 'projects' })
      } catch (error) {
        this.$store.dispatch('popLoading', 'login')

        this.$store.dispatch('setError', error.response.data.message)
      }
    }
  },
  components: {}
}
</script>

<style scoped></style>
