<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <v-form
          ref="registrationForm"
          v-if="!success"
          autocomplete="off"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            outlined
            label="Email"
            v-model="email"
            autocomplete="email"
            :rules="rules.email"
            required
          ></v-text-field>
          <br />

          <v-text-field
            outlined
            label="Password"
            type="password"
            v-model="password"
            autocomplete="new-password"
            :rules="rules.password"
            required
          ></v-text-field>
        </v-form>
        <br />

        <v-btn depressed color="primary" v-if="!success" @click="signUp"
          >Sign Up</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      rules: {
        username: [
          (v) => !!v || 'Username is required',
          (v) =>
            /^[a-zA-Z0-9]{3,32}$/.test(v) ||
            'The username provided must contain at least 3 characters, and may only contain letters and numbers.'
        ],
        email: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        password: [
          (v) => !!v || 'Password is required',
          (v) =>
            /^(?=.{8,})/.test(v) ||
            'The password provided must contain ate least 8 characters'
        ]
      },

      valid: true,
      email: '',
      password: '',
      username: '',
      error: null,
      success: null,
      usernameCheck: false
    }
  },

  methods: {
    async signUp() {
      this.$store.dispatch('pushLoading', 'signUp')

      try {
        const response = (
          await AuthenticationService.signUp({
            email: this.email,
            password: this.password
          })
        ).data
        this.error = null
        this.$router.push({ name: 'about' })
        this.$store.dispatch('setSuccess', response.message)
        this.$store.dispatch('popLoading', 'signUp')
      } catch (error) {
        this.$refs.registrationForm.validate()
        this.$store.dispatch('popLoading', 'signUp')
        this.$store.dispatch('setError', error.response.data.message)
      }
    }
  },
  components: {}
}
</script>

<style scoped></style>
