<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <panel title="Reset Password">
          <form name="reset-password-form">
            <v-text-field
              v-if="!success"
              label="New Password"
              type="password"
              v-model="newPassword"
              autocomplete="true"
            ></v-text-field>
            <br />
            <v-text-field
              v-if="!success"
              label="Verify Password"
              type="password"
              v-model="verifyPassword"
              autocomplete="true"
            ></v-text-field>
            <br />
            <v-alert v-if="error" type="error" class="error" v-html="error" />
            <v-alert
              v-if="success"
              type="success"
              class="success"
              v-html="success"
            />
            <br />

            <v-btn-toggle tile group>
              <div class="ms-2">
                <v-btn v-if="!success" @click="reset_password"
                  >Reset Password</v-btn
                >
              </div>
            </v-btn-toggle>
          </form>
        </panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      newPassword: '',
      verifyPassword: '',
      error: null,
      success: null,
    }
  },

  async mounted() {
    //do a request to backend for all the bingo
    //this.bingo = (await SongsService.index()).data
  },
  methods: {
    async reset_password() {
      try {
        const response = await AuthenticationService.reset_password({
          token: this.$route.query.token,
          password: this.newPassword,
          verifyPassword: this.verifyPassword,
          user_id: this.$route.query.user_id,
        })
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
        this.success = response.data.success
      } catch (error) {
        this.error = error.response.data.error
      }
    },
  },
  components: {},
}
</script>

<style scoped></style>
