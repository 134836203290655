import Axios from 'axios'
import store from '@/store/store'
const VUE_APP_SERVER =
  process.env.VUE_APP_PORTFOLIO_SERVER_HOST +
  `:` +
  process.env.VUE_APP_PORTFOLIO_SERVER_PORT

export default () => {
  return Axios.create({
    baseURL: `https://` + VUE_APP_SERVER,
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
}
