import Api from '@/services/Api'

export default {
  getProjectArray(query) {
    return Api().get('getProjectArray', {
      params: query
    })
  },

  sendMessage(data) {
    return Api().post('sendMessage', data)
  }
}
