import Vue from 'vue'
import Router from 'vue-router'
import SignUp from '@/components/Logins/SignUp'
import Register from '@/components/Logins/Register'
import Login from '@/components/Logins/Login'
import PreferenceForm from '@/components/Logins/PreferenceForm'
import ResetPassword from '@/components/Logins/ResetPassword'
import RequestResetPassword from '@/components/Logins/RequestResetPassword'
import Projects from '@/components/Core/Projects'
import About from '@/components/Core/About'
import Contact from '@/components/Core/Contact'
import store from '@/store/store'

Vue.use(Router)

// create object router with the valid initialization
const router = new Router({
  routes: [
    {
      path: '/sign_up',
      name: 'sign_up',
      component: SignUp
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/request_reset_password',
      name: 'request_reset_password',
      component: RequestResetPassword
    },
    {
      path: '/resetpassword',
      name: 'resetpasswordform',
      component: ResetPassword
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },

    {
      path: '/projects',
      name: 'projects',
      component: Projects
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/preference_form',
      name: 'preference_form',
      component: PreferenceForm
    },

    {
      path: '*',
      redirect: 'about'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (
    //Unlogged in users can only see certain pages
    to.name !== 'login' &&
    to.name !== 'about' &&
    to.name !== 'contact' &&
    to.name !== 'projects' &&
    to.name !== 'sign_up' &&
    to.name !== 'register' &&
    to.name !== 'resetpassword' &&
    to.name !== 'request_reset_password' &&
    !store.state.user
  ) {
    next({ name: 'about' })
  } else next()
})

export default router
