<template>
  <v-container>
    <v-row v-if="localUser">
      <v-col class="text-center">
        <form ref="form">
          <v-text-field
            outlined
            disabled
            label="Email"
            id="email"
            name="email"
            v-model="localUser.email"
            autocomplete="email"
            placeholder=" "
            persistent-placeholder
          ></v-text-field>

          <v-text-field
            outlined
            label="First Name"
            id="firstName"
            name="firstName"
            autocomplete="given-name"
            persistent-hint
            v-model="localUser.firstName"
          ></v-text-field>

          <v-text-field
            outlined
            label="Last Name"
            id="lastName"
            name="lastName"
            persistent-hint
            autocomplete="family-name"
            v-model="localUser.lastName"
          ></v-text-field>

          <v-btn depressed color="primary" @click="savePreferences" class="mx-2"
            >Save</v-btn
          >
          <v-btn depressed color="primary" @click="showDeleteForm" class="mx-2">
            <v-icon left> mdi-delete </v-icon>
            Delete Account
          </v-btn>
        </form>
      </v-col>
    </v-row>
    <DeleteUserForm
      v-if="isDeleteFormVisible"
      v-on:closeDialog="isDeleteFormVisible = false"
    />
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import DeleteUserForm from '../Logins/DeleteUserForm'
import lodash from 'lodash'

export default {
  components: { DeleteUserForm },

  data() {
    return {
      localUser: null,
      isDeleteFormVisible: false
    }
  },
  methods: {
    showDeleteForm(item) {
      this.isDeleteFormVisible = true
    },
    async setupForm() {
      this.localUser = lodash.cloneDeep(this.$store.state.user)
    },
    async savePreferences() {
      this.$store.dispatch('pushLoading', 'savePreferences')
      try {
        const response = (await AuthenticationService.putUser(this.localUser))
          .data

        this.$store.dispatch('setSuccess', response.message)

        const userResponse = (
          await AuthenticationService.getUser({ id: this.$store.state.user.id })
        ).data

        this.$store.state.user = userResponse.data.user

        this.$store.dispatch('popLoading', 'savePreferences')
      } catch (error) {
        this.$store.dispatch('popLoading', 'savePreferences')

        this.$store.dispatch('setError', error.response.data.message)
      }
    }
  },
  async mounted() {
    this.setupForm()
  }
}
</script>

<style scoped></style>
