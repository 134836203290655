import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' //Globally import VTextField
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'en-CA',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: colors.teal.darken3,
        info: colors.teal.lighten2,
        secondary: colors.teal.lighten4
      }
    }
  }
})
