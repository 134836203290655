<template>
  <v-container>
    <!-- <v-overlay
      absolute
      :style="{
        'background-image': `url(${image})`,
        'background-size': 'cover'
      }"
    > -->
    <v-layout column align-center>
      <v-row>
        <v-col>
          <h1
            :class="{
              'subheading  text-center': $vuetify.breakpoint.smAndDown,
              'mb-2 jumbo text-center': $vuetify.breakpoint.mdAndUp
            }"
          >
            BenSmall.Ca
          </h1>
        </v-col>
      </v-row>
      <v-row
        ><v-col>
          <v-icon x-large class="primary--text text-center">
            mdi-cellphone-link</v-icon
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <p class="primary--text text-center">
            I am a full stack web-developer with 12+ years of successful
            experience.<br />
            I also have a university degree in science and can create a
            top-notch website or web application for your scientific project.
          </p>
        </v-col>
      </v-row>
    </v-layout>
    <!-- </v-overlay> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      image:
        'https://images.unsplash.com/photo-1610650394144-a778795cf585?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80&h=600&w=600',
      error: null,
      success: null
    }
  },
  computed: {},
  created() {}
}
</script>
<style scoped>
.jumbo {
  font-weight: 300;
  font-size: 4em;
}
</style>
