<template>
  <v-app-bar app color="white" flat>
    <v-spacer></v-spacer>
    <v-tabs centered show-arrows color="grey darken-1" v-if="$store.state.user">
      <v-tab :to="{ name: 'about' }"> About </v-tab>
      <!-- <v-tab :to="{ name: 'projects' }"> Projects </v-tab> -->
      <v-tab @click="logout()"> Log Out </v-tab>
    </v-tabs>

    <v-tabs centered color="grey darken-1" v-if="!$store.state.user">
      <v-tab :to="{ name: 'about' }"> About </v-tab>
      <!-- <v-tab :to="{ name: 'projects' }"> Projects </v-tab> -->
      <v-tab :to="{ name: 'contact' }"> Contact </v-tab>
    </v-tabs>

    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      error: null,
      success: null,
      activeTab: null,
      drawer: false,
      group: null
    }
  },
  computed: {},

  methods: {
    logout() {
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      this.$router.push({ name: 'about' })
    }
  },
  created() {}
}
</script>

<style scoped></style>
