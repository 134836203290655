<template>
  <v-container>
    <v-row class="my-4">
      <v-col>
        <h2 class="my-4">Register</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      error: null,
      success: null
    }
  },
  async mounted() {
    this.register()
  },
  methods: {
    async register() {
      this.$store.dispatch('pushLoading', 'register')

      try {
        const response = await AuthenticationService.register({
          registerToken: this.$route.query.registerToken,
          userId: this.$route.query.userId
        })
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
        this.$store.dispatch('popLoading', 'register')
        this.$router.push({ name: 'projects' })
        this.$store.dispatch('setSuccess', response.message)
      } catch (error) {
        this.$store.dispatch('popLoading', 'register')

        this.$store.dispatch('setError', error.response.data.message)
      }
    }
  },
  components: {}
}
</script>

<style scoped></style>
