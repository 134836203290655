import Api from '@/services/Api'

export default {
  signUp(credentials) {
    return Api().post('sign_up', credentials)
  },
  register(credentials) {
    return Api().post('register', credentials)
  },
  login(credentials) {
    return Api().post('login', credentials)
  },
  sendResetPasswordEmail(credentials) {
    return Api().post('send_reset_password_email', credentials)
  },
  resetPassword(passwords) {
    return Api().post('reset_password', passwords)
  },
  getUsers() {
    return Api().get('users')
  },
  getUser(query) {
    return Api().get('getUser', {
      params: query
    })
  },
  putUser(user) {
    return Api().post('putUser', user)
  },
  deleteUser(user) {
    return Api().post('deleteUser', user)
  }
}
