<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <form name="request-password-reset-form">
          <v-text-field label="Email" v-model="email" outlined></v-text-field>

          <br />

          <v-btn depressed color="primary" @click="send_reset_password_email"
            >Request Password Reset</v-btn
          >
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      email: '',
      error: null,
      success: null
    }
  },

  async mounted() {
    //do a request to backend for all the bingo
    //this.bingo = (await SongsService.index()).data
  },
  methods: {
    async send_reset_password_email() {
      try {
        const response = await AuthenticationService.send_reset_password_email({
          email: this.email
        })
        this.error = null
        this.success = response.data.success
      } catch (error) {
        this.error = error.response.data.error
      }
    }
  },
  components: {}
}
</script>

<style scoped></style>
